<template>
  <li class="members-content__item">
    <div class="members">
      <router-link
        class="timeline-vacation__user"
        :to="{ name: 'UserInfo', params: { user_id: item.info.id } }"
        target="_blank"
      >
        <Avatar :user="item.info" :showInfo="true" :showRole="false" />
      </router-link>
      <div class="members__buttons">
        <button
          class="members__button"
          @click="handleModerator"
          :class="{ members__button_self: isSelf && isAdmin }"
        >
          <svg
            v-if="item.is_moderator"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.4999 14.8921L13.9583 16.9838C14.5916 17.3671 15.3666 16.8005 15.1999 16.0838L14.2833 12.1505L17.3416 9.50046C17.8999 9.01712 17.5999 8.10046 16.8666 8.04212L12.8416 7.70046L11.2666 3.98379C10.9833 3.30879 10.0166 3.30879 9.73327 3.98379L8.15827 7.69212L4.13327 8.03379C3.39994 8.09212 3.09994 9.00879 3.65827 9.49212L6.7166 12.1421L5.79994 16.0755C5.63327 16.7921 6.40827 17.3588 7.0416 16.9755L10.4999 14.8921Z"
              fill="#FFE880"
            />
          </svg>
          <i class="icon-favorites" v-if="!item.is_moderator && isAdmin"></i>
        </button>
        <button class="members__button" v-if="isAdmin && !isSelf" @click="handleDelete">
          <i class="icon-delete"></i>
        </button>
      </div>
    </div>
  </li>
</template>

<script>
import Avatar from "@/modules/Messenger/components/ChatItem/Avatar";
import { mapActions, mapState } from "vuex";

export default {
  name: "MembersItem",
  mixins: [],
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  components: { Avatar },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["confirmAction"]),
    handleModerator() {
      if (!this.isSelf && this.isAdmin) {
        if (this.item.is_moderator && this.moderators.length === 1) return false;
        if (this.moderators.length === 5 && !this.item.is_moderator) {
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 3000,
            label: `Нельзя назначить больше 5 модераторов`
          });
        } else {
          this.confirmAction({
            action: {
              type: "moderator",
              id: this.item.id,
              is_moderator: !this.item.is_moderator,
              user_id: this.item.user_id
            },
            data: {
              show: true,
              title: this.item.is_moderator ? "Снять с должности?" : "Назначить модератором?",
              text: this.item.is_moderator
                ? "Вы можете назначить его модератором снова."
                : "У него будут расширенные права.",
              button: this.item.is_moderator ? "Снять" : "Назначить",
              type: "update-member"
            }
          });
        }
      }
    },
    handleDelete() {
      this.confirmAction({
        action: {
          type: "delete",
          id: this.currentChat.channel.id,
          user_id: this.item.user_id
        },
        data: {
          show: true,
          title: "Удалить участника?",
          text: "Вы сможете добавить его снова.",
          button: "Удалить",
          type: "delete-member"
        }
      });
    }
  },
  computed: {
    ...mapState("MessengerModule", ["currentChat", "users"]),
    isAdmin() {
      return (
        this.users.find((item) => item.user_id === this.getUserId)?.is_moderator || this.isAdminChat
      );
    },
    isAdminChat() {
      return this.$store.getters.getDataInfoUser.roles.includes("chat_admin");
    },
    moderators() {
      return this.users.filter((item) => item.is_moderator);
    },
    isSelf() {
      return this.item.user_id === this.getUserId;
    },
    getUserId() {
      return this.$store.getters.getUserId;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.members {
  display: flex;
  justify-content: space-between;
  width: 100%;
  &__buttons {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin-right: 8px;
  }
  &__button {
    all: unset;
    cursor: pointer;
    i {
      font-size: 20px;
      color: $icon-subdued;
      transition: all 0.3s ease;
      @media (hover: hover) {
        &:hover {
          color: $text-subdued;
        }
      }
    }
    &_self {
      cursor: default;
    }
  }
  svg {
    @media (hover: hover) {
      &:hover {
        path {
          fill: darken(#ffe880, 10%);
        }
      }
    }
  }
}
</style>
