<template>
  <div class="members-content">
    <ul class="members-content__list">
      <MembersItem :item="item" v-for="item in files" :key="item.id" />
    </ul>
  </div>
</template>

<script>
import MembersItem from "@/modules/Messenger/components/ChatModal/AttachedModal/MembersItem";
import { mapState } from "vuex";
export default {
  name: "MembersContent",
  mixins: [],
  props: {
    files: {
      type: Array
    }
  },
  components: { MembersItem },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapState("MessengerModule", ["currentChat", "users"]),
    sortFiles() {
      const users = this.users;
      return users.sort(function (a, b) {
        return b.is_moderator - a.is_moderator;
      });
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.members-content {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 24px;
  overflow-y: auto;
  margin-bottom: 60px;
  @media screen and (max-width: $xs) {
    margin-bottom: 0;
  }
  &__list {
    all: unset;
    list-style: none;
    overflow: auto;
    height: 100%;
  }
  /deep/ {
    .members-content__item {
      margin-bottom: 8px;
      padding: 4px;
      background-color: #fff;
      border-radius: 4px;
      transition: all 0.3s ease;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      @media (hover: hover) {
        &:hover {
          background-color: $bg-gray-200;
        }
      }
    }
    .avatar {
      &__img {
        @media screen and (max-width: $xxxl) {
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }
        @media screen and (max-width: $lg) {
          width: 55px;
          height: 55px;
          margin-right: 16px;
        }
      }
      &__name {
        max-width: 180px;
        @media screen and (max-width: $xxxl) {
          font-size: 14px;
          max-width: 120px;
        }
        @media screen and (max-width: $lg) {
          font-size: 16px;
          max-width: 180px;
        }
        @media screen and (max-width: $xs) {
          max-width: 160px;
        }
      }
      &__tag {
        max-width: 180px;
        @media screen and (max-width: $xxxl) {
          font-size: 12px;
          max-width: 120px;
        }
        @media screen and (max-width: $lg) {
          font-size: 14px;
          max-width: 180px;
        }
        @media screen and (max-width: $xs) {
          max-width: 160px;
        }
      }
    }
  }
}
</style>
